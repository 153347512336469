import React, { useState, useEffect } from "react"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"

// Import CSS
import "./../../styles/free-tools.css"
import "./../../styles/products.css"

// Import components
import GetStartedBanner from "./../../components/get-started-footer"

const SLACalculator = ({ data }) => (
  <Layout>
    <SEO
      title="SLA and Uptime Calculator - Odown Free Tools"
      description=" Calculate SLA and uptime with this Free Odown SLA Uptime Calculator. Make sure you’re getting the best uptime from your vendor."
      pathname={`/free-tools/sla-calculator/`}
      image={{
        src: "/general-image-og--sla_calculator.jpg",
        width: 1600,
        height: 800,
      }}
    />
    {/* HEADER */}
    <HeadingSection />
    {/* Main Component */}
    <MainComponent />
    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

/**
 * Heading Section
 */
const HeadingSection = ({ data }) => {
  return (
    <div className="u-features freetools-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
          Free SLA & Uptime Calculator
        </h1>
        <div className="FreeTool-heading__description">
          Make sure your vendor is living up to their uptime promises!
        </div>
      </div>
      <div className="FreeTool-heading__button">
        <OutboundLink
          className="btn-primary"
          eventCategory="Launch the Odown App"
          eventAction="Click"
          eventLabel="Goto signup"
          href="https://app.odown.io/signup"
        >
          Trial Odown free for 14 days
        </OutboundLink>
      </div>
    </div>
  )
}

const AboutSLAChecker = () => {
  const [uptimePercentage, setUptimePercentage] = useState(0)
  const [downtime, setDowntime] = useState({
    daily: "1d",
    weekly: "7d",
    monthly: "30d",
    quarterly: "120d",
    yearly: "1y",
  })

  // Function to format seconds into a readable string
  function formatDownTimeString(seconds) {
    const hrs = Math.floor(seconds / 3600)
    const mins = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60

    const parts = []
    if (hrs > 0) parts.push(`${hrs} hour${hrs > 1 ? "s" : ""}`)
    if (mins > 0) parts.push(`${mins} minute${mins > 1 ? "s" : ""}`)
    if (secs > 0) parts.push(`${secs} second${secs > 1 ? "s" : ""}`)

    let formattedString = parts.join(" ")
    if (formattedString?.length === 0) return "No downtime"
    return formattedString
  }

  const computeDowntime = () => {
    let downtime = {
      daily: 0,
      weekly: 0,
      monthly: 0,
      quarterly: 0,
      yearly: 0,
    }

    // If the uptime is 100% or greater then the downtime is 0
    if (uptimePercentage >= 100) {
      downtime.daily = "No downtime"
      downtime.weekly = "No downtime"
      downtime.monthly = "No downtime"
      downtime.quarterly = "No downtime"
      downtime.yearly = "No downtime"
      setDowntime({ ...downtime })
      return
    }

    // compute all in seconds and from there convert it into a string containing format hours, minutes, seconds
    downtime.daily = Math.round((1 - uptimePercentage / 100) * 86400)
    downtime.weekly = Math.round((1 - uptimePercentage / 100) * 604800)
    downtime.monthly = Math.round((1 - uptimePercentage / 100) * 2592000)
    downtime.quarterly = Math.round((1 - uptimePercentage / 100) * 10368000)
    downtime.yearly = Math.round((1 - uptimePercentage / 100) * 31536000)

    // Convert to readable format
    downtime.daily = formatDownTimeString(downtime.daily)
    downtime.weekly = formatDownTimeString(downtime.weekly)
    downtime.monthly = formatDownTimeString(downtime.monthly)
    downtime.quarterly = formatDownTimeString(downtime.quarterly)
    downtime.yearly = formatDownTimeString(downtime.yearly)

    setDowntime({ ...downtime })
  }

  const onChangeUptimePercentage = e => {
    setUptimePercentage(e.target.value)
  }

  return (
    <section className="About-SLACalculator-section">
      <div className="sla-container">
        <div className="u-main-feature__wrapper">
          <div className="sla-result-card">
            <div className="sla-section">
              <span className="sla-label">What is the uptime percentage?</span>
              <div className="sla-input-button-row">
                <div className="input-container">
                  <input
                    type="number"
                    placeholder="99.95"
                    className="sla-input"
                    onChange={e => {
                      onChangeUptimePercentage(e)
                    }}
                    value={uptimePercentage || ""}
                  />
                  <span className="suffix">%</span>
                </div>
                <button className={"sla-test-button"} onClick={computeDowntime}>
                  Calculate downtime
                </button>
              </div>
            </div>
            <div className="sla-result-item">
              <span className="result-label">Daily</span>
              <span className="result-value">{downtime.daily}</span>
            </div>
            <div className="sla-result-item">
              <span className="result-label">Weekly</span>
              <span className="result-value">{downtime.weekly}</span>
            </div>
            <div className="sla-result-item">
              <span className="result-label">Monthly</span>
              <span className="result-value">{downtime.monthly}</span>
            </div>
            <div className="sla-result-item">
              <span className="result-label">Quarterly</span>
              <span className="result-value">{downtime.quarterly}</span>
            </div>
            <div className="sla-result-item">
              <span className="result-label">Yearly</span>
              <span className="result-value">{downtime.yearly}</span>
            </div>
          </div>
          <div className="u-main-feature__text-content">
            <p className="content-desc-2">
              When you signup with a service vendor, you need to understand what
              uptime promises they guarantee and what those percentages mean.
              For context, an SLA of either 95 or 98 percent availability per
              year sounds amazing, but do you know what that really means to
              your business or online service? At 95%, you can expect around 18
              days of unexpected downtime. At 98%, you can expect over 7 days a
              year of downtime. You will need to evaluate your critical business
              needs and say to yourself can you afford this sort of downtime?
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const SLAContent = () => {
  return (
    <section className="SLAContent-section">
      <div className="content-container">
        <div className="content-wrapper">
          <div className="text-content">
            <h2 className="content-title-1">What is uptime?</h2>
            <p className="content-desc-2">
              Uptime is the amount of time that a service is available and
              operational, typically expressed in percentage.
            </p>

            <h2 className="content-title-1">What is SLA?</h2>
            <p className="content-desc-2">
              A service-level agreement (SLA) defines the level of service you
              expect from a vendor, laying out the metrics by which service is
              measured, as well as remedies or penalties should agreed-on
              service levels not be achieved.
            </p>

            <h2 className="content-title-1">How to prevent downtime?</h2>
            <p className="content-desc-2">
              Redundancy, monitoring and alerting are key to ensure a safe and
              reliable service. Odown is here to help with monitoring your
              critical infrastructure.
            </p>

            <h2 className="content-title-1">Percentiles</h2>
            <p className="content-desc-2">
              The difference between 99.9% and 99.99% really matters, especially
              for a large enterprise solution uses across multiple regions.{" "}
              <br></br>
              We explain this below:<br></br>
              99.9% uptime equals to a period of downtime of 1 minute and 26
              seconds per day.<br></br>
              99.99% uptime equals to a period of downtime of less than 9
              seconds per day.<br></br>
              99.999% uptime equals to a period of downtime of less than 1
              second per day.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const MainComponent = () => {
  return (
    <div className="container-sla">
      <SLAContent />
      <AboutSLAChecker />
    </div>
  )
}

export default SLACalculator
